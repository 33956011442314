import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BiSolidHome } from "react-icons/bi";

export default function Header() {
  // const UpdateFontSize = (action) => {
  //   let Body = document.getElementById("root");
  //   let fSize = window
  //     .getComputedStyle(Body, null)
  //     .getPropertyValue("font-size");
  //   let FontSize = parseFloat(fSize);
  //   console.log("Font", FontSize);
  //   if (action === "increase") {
  //     Body.style.fontSize = FontSize + 1 + "px";
  //   } else if (action === "decrease") {
  //     Body.style.fontSize = FontSize - 1 + "px";
  //   } else {
  //     // Body.style.transform = "scale(.80)"
  //     Body.style.fontSize = 16 + "px";
  //   }
  // };
  return (
    <div className="header--fixed">
      <Navbar expand="lg" className="header--top">
        <div
          style={{
            // margin: "0 4%",
            width: "100%",
            display: "flex",
            // justifyContent: "space-between",
          }}
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="header--links">
              <Nav.Link href="/" className="header--link header--home">
                <BiSolidHome size={24} />
              </Nav.Link>
              <NavDropdown
                title="About Us"
                className="header--link"
                href="/about"
              >
                <NavDropdown.Item href="/about">Our Story</NavDropdown.Item>
                <NavDropdown.Item href="/about/chairman's-message">
                  Chairman's Message
                </NavDropdown.Item>
                <NavDropdown.Item href="/about/board-of-directors">
                  Board of Directors
                </NavDropdown.Item>
                <NavDropdown.Item href="/about/pmrc">PMRC</NavDropdown.Item>
                <NavDropdown.Item href="/about/team">Team</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/program" className="header--link">
                Programs
              </Nav.Link>
              <Nav.Link href="/startups" className="header--link">
                Startups
              </Nav.Link>
              <Nav.Link href="/mentors" className="header--link">
                Mentors
              </Nav.Link>
              <Nav.Link href="/fpo" className="header--link">
                FPOs
              </Nav.Link>
              <Nav.Link href="/events" className="header--link">
                Events
              </Nav.Link>
              {/* <Nav.Link href="" className="header--link">
                Resources
              </Nav.Link> */}
              <NavDropdown title="Notices" className="header--link">
                <NavDropdown.Item href="/tenders">Tenders</NavDropdown.Item>
                <NavDropdown.Item href="/recruitment">
                  Recruitment
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Careers" className="header--link">
                <NavDropdown.Item href="/recruitment">
                  Recruitment at ABIF
                </NavDropdown.Item>
                <NavDropdown.Item href="/recruitment-in-startups">
                  Recruitment in Startups
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/govt-initiatives" className="header--link">
              Govt. Initiatives
              </Nav.Link>
              <Nav.Link href="/contact" className="header--link">
                Contact Us
              </Nav.Link>{" "}
              <Nav.Link
                href="/apply/incubation"
                className="header--link header--link--apply header--link--apply--start"
              >
                Apply for Incubation
              </Nav.Link>
              <Nav.Link
                href="/apply/mentor"
                className="header--link header--link--apply"
              >
                Be a Mentor
              </Nav.Link>
              {/* <div className="header--font--group">
                <button
                  onClick={() => UpdateFontSize("decrease")}
                  className="header--font--btn"
                >
                  A-
                </button>
                <button
                  onClick={() => UpdateFontSize("")}
                  className="header--font--btn"
                >
                  A
                </button>
                <button
                  onClick={() => UpdateFontSize("increase")}
                  className="header--font--btn"
                >
                  A+
                </button>
              </div> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Navbar expand="lg" className="bg-body-tertiary header">
        <div
          style={{
            margin: "0 4%",
            width: "100%",
            display: "flex",
            // justifyContent: "space-between",
          }}
        >
          <Navbar.Brand href="/" className="header--brand">
            <img
              src="/assets/images/logo.png"
              alt="Logo"
              className="header--logo"
            />
            कृषि व्यवसाय उद्भवन प्रतिष्ठान
            <br /> भारतीय प्रौद्योगिकी संस्थान खड़गपुर <br />
            AGRI BUSINESS INCUBATION FOUNDATION
            <br /> INDIAN INSTITUTE OF TECHNOLOGY KHARAGPUR
          </Navbar.Brand>
          <div className="" style={{ marginLeft: "auto" }}>
            <p className="header--logo--text">An Initiative of</p>
            <div className="">
              <img
                src="/assets/images/iitkgplogo.png"
                alt="Logo"
                className="header--logo--one"
              />
              <img
                src="/assets/images/nabard.png"
                alt="Logo"
                className="header--logo--two"
              />
            </div>
          </div>
          <img
            src="/assets/images/g20_aam.png"
            alt="Logo"
            className="header--logo--three"
          />
        </div>
      </Navbar>
    </div>
  );
}
