import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import MemberCard from "../../components/Cards/MemberCard";

import { useEffect, useState } from "react";
import { getMemberList } from "../../api/api";

export default function Team() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getMemberList("team").then((result) => {
      setList(result);
    });
  }, []);
  return (
    <>
      <Container className="about__members">
        <Row>
          <h2 className="heading--primary">Team</h2>
        </Row>
        {list.map((item, index) => (
          <MemberCard member={item} />
        ))}
        {/* <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Position</th>
              <th>Info</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.position}</td>
                <td>{item.info}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </Container>
    </>
  );
}
