import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import OurImpact from "../../components/Home/OurImpact";
import { useEffect, useState } from "react";
import { getContent } from "../../api/api";

export default function OurStory() {
  const [aboutContent, setAboutContent] = useState([]);
  useEffect(() => {
    getContent("about").then((result) => {
      setAboutContent(result);
    });
  }, []);
  return (
    <div className="about__story">
      <div className="">
        <Container>
          <Row>
            <h2 className="heading--primary">Our Story</h2>
          </Row>
          <Row>
            <p className="about__story--content">
              <img
                src="/assets/images/abif-building.jpg"
                alt="building"
                className="about__story--image"
              />
              {aboutContent[0]?.content} <br />
              {aboutContent[1]?.content}
            </p>
          </Row>
        </Container>
      </div>
      <OurImpact />
      <div className="">
        <Container>
          <Row>
            <h2 className="heading--primary" style={{ marginTop: "1em" }}>
              Organogram and Major Stakeholders
            </h2>
          </Row>
          <Row>
            <img src="/assets/images/abif-organogram.png" alt="organogram" />
          </Row>
        </Container>
      </div>
    </div>
  );
}
