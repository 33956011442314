import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import { useEffect, useState } from "react";
import { getStartupList } from "../api/api";

export default function Startups() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    getStartupList().then((result) => {
      setData(result);
    });
  }, []);
  const itemsPerPage = 12;
  return (
    <>
      <Container className="startup">
        <Row>
          <h2 className="heading--primary">Startups</h2>
        </Row>
        <Row>
          {data
            ?.slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage
            )
            ?.map((item, index) => (
              <Col
                sm={12}
                md={3}
                style={{
                  alignItems: "stretch",
                  marginBottom: "1em",
                }}
                key={item.id}
              >
                <Card
                  style={
                    {
                      // height: "100%",
                    }
                  }
                  className="startup--card wow animate__animated animate__fadeInUp"
                >
                  <div className="startup--card--content">
                    <Card.Img
                      variant="top"
                      src={item.logo}
                      className="startup--card--image"
                      // style={{ marginTop: "auto", marginBottom: "auto" }}
                    />
                    <Card.Body
                      className="startup--card--body"
                      style={{
                        marginBottom: 0,
                        maxHeight: "max-content",
                        flex: "0 1 auto",
                      }}
                    >
                      <Card.Title style={{ display: "block" }}>
                        {item.name}
                      </Card.Title>
                      <Card.Text style={{ display: "block" }}>
                        <strong>Focus Sector:</strong>
                        <br />
                        {item.info}
                        <br />
                        <a href={`/startups/${item.slug}`}>View More</a>
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "max-content",
            margin: "0 auto",
          }}
        >
          <Pagination style={{ alignItems: "center", textAlign: "center" }}>
            {Array.from(
              { length: Math.ceil(data.length / itemsPerPage) },
              (_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePagination(index + 1)}
                  // className="button--primary"
                >
                  {index + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </Row>
      </Container>
    </>
  );
}
