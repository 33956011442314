import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {
  FaTractor,
  FaRobot,
  FaBiohazard,
  FaRuler,
  FaWater,
  FaLandmark,
  FaBox,
  FaBolt,
  FaShippingFast,
  FaRecycle,
  FaHorse,
  FaBug,
  FaFish,
  FaGlobe,
  FaFlask,
  FaChalkboardTeacher,
  FaShieldAlt,
  FaChartLine,
  FaHandsHelping,
  FaLightbulb,
} from "react-icons/fa";

export default function Program() {
  return (
    <div className="program">
      <Container className="">
        <Row>
          <h2 className="heading--primary wow animate__animated animate__fadeInUp">
            INCUBATION PROGRAM
          </h2>
        </Row>
        <div className="mt-3">
          <div className="program--image--div wow animate__animated animate__fadeInUp">
            <img
              src="/assets/images/program.png"
              className="program--image "
              alt="program"
            />
            <a href="/apply/incubation">
              <Button className="w-100 button--primary">Apply Now</Button>
            </a>
          </div>
          <h3 className="heading--secondary">About the program</h3>
          <p className="program--content">
            The Agricultural and Food Engineering (AgFE) Department of the IIT
            Kharagpur - the only department of its kind in the country's IIT
            system is hosting the NABARD-funded Agri- Business Incubation Centre
            (ABIF) for promotion of agri-business. Under this program, the ABIF
            IIT Kharagpur aims to nurture early-stage innovative enterprise that
            have high growth potential in agribusiness by adding value and
            linking to producers and markets
          </p>
          <h3 className="heading--secondary">Objective</h3>
          <p className="program--content">
            To incubate innovative ideas and support (technology and business
            mentorship, lab access, access to funding opportunities etc)
            ideation to Proof-of-Concept (PoC) in agriculture and food
            technology domain for creating Agri-preneures.
          </p>
          <h3 className="heading--secondary">Eligibility</h3>
          <ol type="a" className="program--content">
            <li>
              Individuals, proprietary concerns, FPOs, OFPOs, partnerships, LLPs
              etc.
            </li>
            <li>
              Individuals/entities with minimum 51% ownership by Indian
              nationals
            </li>
            <li>
              Individuals/entities ready to be incubated at ABIF IIT Kharagpur
            </li>
            <li>
              If entity, they should be registered under the Govt. of
              India/States/UTs etc.
            </li>
            <li>
              If entity, the incorporation date should not be earlier than 10
              years from the date of application.
            </li>
            <li>
              Startups already incubated elsewhere will not be eligible.
              Although they may apply after the end of their incubation period
              with the other organisation.
            </li>
          </ol>
          <h3 className="heading--secondary">Duration</h3>
          <p className="program--content">
            The incubation is provided for a period of up to 12 months. The
            project is implemented in a milestone-based manner.
          </p>
          <h3 className="heading--secondary">Call for application</h3>
          <ul className="program--content">
            <li>Open round the year</li>
            <li>
              Link:{" "}
              <a href="/apply/incubation" target="_blank">
                Click Here
              </a>
            </li>
            <li>
              Contact Email ID:{" "}
              <a href="mailto:info@abifiitkgp.com">info@abifiitkgp.com</a>
            </li>
          </ul>
          <h3 className="heading--secondary">Focus Sector</h3>
          <p className="program--content">
            ABIF IIT Kharagpur supports innovation under agriculture and allied
            sectors, or any innovative enterprise idea which can in turn benefit
            the rural masses of the country. The business proposals can be
            broadly submitted in any of the following categories:
            <ul className="program--list program--list-1">
              <li>
                <FaTractor size={72} /> Farm Mechanization
              </li>
              <li>
                <FaRobot size={72} /> AI/Machine Learning/IoT in Agriculture
              </li>
              <li>
                <FaBiohazard size={72} /> Agricultural Bio-technology
              </li>
              <li>
                <FaRuler size={72} /> Precision Farming
              </li>
              <li>
                <FaWater size={72} /> Soil & Water Conservation Engineering
              </li>
              <li>
                <FaLandmark size={72} /> Food Processing & Value addition
              </li>
              <li>
                <FaBox size={72} /> Food packaging & storage
              </li>
              <li>
                <FaBolt size={72} /> Renewable energy
              </li>
              <li>
                <FaShippingFast size={72} /> Agri supply chain management
              </li>
              <li>
                <FaRecycle size={72} /> Waste to wealth
              </li>
              <li>
                <FaHorse size={72} /> Animal Husbandry
              </li>
              <li>
                <FaBug size={72} /> Apiary
              </li>
              <li>
                <FaFish size={72} /> Fisheries
              </li>
              <li>
                <FaGlobe size={72} /> Others areas related to agriculture and
                rural development
              </li>
            </ul>
          </p>
          <h3 className="heading--secondary">Benefits</h3>
          <p className="program--content">
            ABIF provide continuous mentoring support to the incubates,
            including but not limited to the following:
            <ol type="a" className="program--list program--list-2 mt-2">
              <li>
                <FaFlask size={72} /> Product development and testing
              </li>
              <li>
                <FaChalkboardTeacher size={72} /> Technical Mentoring
              </li>
              <li>
                <FaShieldAlt size={72} /> IP Support
              </li>
              <li>
                <FaChartLine size={72} /> Business Mentoring
              </li>
              <li>
                <FaHandsHelping size={72} /> Facilitation of grants &
                investments
              </li>
              <li>
                <FaLightbulb size={72} /> Any other assistance on case-to-case
                basis
              </li>
            </ol>
          </p>
        </div>
      </Container>
    </div>
  );
}
