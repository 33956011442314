import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

export default function ChairmansMessage() {
  return (
    <>
      <Container className="about__message">
        <Row>
          <h2 className="heading--primary">Chairman's Message</h2>
        </Row>
        <Row>
          <Col sm={12} md={3}>
            <img
              src="/assets/images/chairman.jpg"
              alt="chairman"
              className="about__message--image"
            />
          </Col>
          <Col sm={12} md={9}>
            <p className="about__message--content">
              Dear Visitors, <br />
              It gives me immense pleasure to welcome you to the Agri Business
              Incubation Foundation IIT Kharagpur!
              <br />
              Agriculture, the backbone of our nation, holds vast untapped
              potential for innovation and entrepreneurship. At our Incubator,
              we firmly believe that nurturing agripreneurs is the key to
              transforming the agricultural landscape. Through visionary ideas
              and sustainable practices, we envision a future where farming
              becomes not only economically viable but also environmentally
              responsible.
              <br /> Our incubator is a haven for passionate individuals seeking
              to revolutionize agriculture. We provide unwavering support,
              cutting-edge infrastructure, and a nurturing ecosystem to turn
              ideas into impactful ventures. With access to top-notch mentors
              and industry experts, our incubator is designed to foster growth,
              innovation, and success. <br />
              As the Chairman of this esteemed Incubator, I encourage you to
              explore the limitless possibilities in agri-business & rural
              development and embark on a journey that will not only enrich your
              lives but also contribute significantly to the welfare of our
              nation and beyond.
              <br /> Let's together sow the seeds of change and harvest a
              bountiful future!
              <br /> <br /> Warm regards, <br />
              Prof. Virendra Kumar Tewari <br /> Chairman, Board of Directors{" "}
              <br />
              Agri Business Incubation Foundation IIT Kharagpur
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
