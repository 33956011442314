import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";

let myInitialValues = {
  name: "",
  email: "",
  phone: "",
  linkedIn: "",
  organization: "",
  position: "",
  bio: "",
  experience: "",
  domain: [],
  domain_other: "",
  more: "",
  available: "",
  cv: null,
};

export default function ApplyMentor() {
  const [data, setData] = useState(myInitialValues);
  const [validated, setValidated] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!data.name) {
      alert("Enter Name");
      return;
    } else if (!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      alert("Enter a valid Email");
      return;
    } else if (!data.phone || data.phone.length !== 10) {
      alert("Enter a valid Phone number(10 Digits");
      return;
    } else if (data.domain.length <= 0) {
      alert("Select atleast one Domain");
      return;
    } else if (data.domain.includes("Other") && !data.domain_other) {
      alert("Enter the other domain");
      return;
    } else if (
      !data.organization ||
      !data.position ||
      !data.bio ||
      !data.experience ||
      !data.available
    ) {
      alert("Fill all the required fields.");
      return;
    }
    if (form.checkValidity() === false) {
      // event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      alert("Fill all required fields.");
      return;
    } else {
      setLoading(true);
      let fd = new FormData();
      let tempDomain = data.domain;
      if (data.domain_other && data.domain.includes("Other")) {
        tempDomain = tempDomain.map((item) =>
          item === "Other" ? data.domain_other : item
        );
      }
      tempDomain = tempDomain.join(", ");
      fd.append("name", data.name);
      fd.append("email", data.email);
      fd.append("phone", data.phone);
      fd.append("linkedIn", data.linkedIn);
      fd.append("organization", data.organization);
      fd.append("position", data.position);
      fd.append("domain", tempDomain);
      fd.append("bio", data.bio);
      fd.append("experience", data.experience);
      fd.append("more", data.more);
      fd.append("available", data.available);
      fd.append("cv", data.cv);
      try {
        fetch(`${process.env.REACT_APP_BASE_URL}/apply/mentor`, {
          headers: {
            Accept: "application/json",
          },
          method: "POST",
          body: fd,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === "success") {
              setValidated(true);
              setFormSubmitted(true);
            }
            setLoading(false);
          });
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Container className="apply">
        <h2 className="heading--primary">
          Mentor Registration Form <br /> Agri Business Incubation Foundation
          IIT Kharagpur
        </h2>
        {formSubmitted ? (
          <h4 className="heading-primary" style={{ padding: "2em 0" }}>
            Thank you for registering yourself as ABIF Mentor!
            <br />
            We will get back to you shortly.
          </h4>
        ) : (
          <div className="apply--info">
            <p>
              Agri Business Incubation Foundation (ABIF), IIT Kharagpur drives
              entrepreneurial success of young Indian professionals by matching
              experienced and supportive mentors with our incubated startups.
              ABIF provides support at a wide range of stages - from ideation
              through incubation - through trainings, classroom sessions
              ,ongoing mentorships and by conducting Investors' Meets.
            </p>
            <p>
              For any queries, please feel free to write to{" "}
              <a href="mailto:info@abifiitkgp.com">info@abifiitkgp.com</a>
            </p>
          </div>
        )}
      </Container>
      <Container className="">
        {!formSubmitted && (
          <Form
            className="apply-form mt-3 mb-3"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Full Name:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Full Name"}
                      name={"name"}
                      required={true}
                      value={data.name}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Email Id:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"email"}
                      placeholder={"your_email@email.com"}
                      name={"email"}
                      required={true}
                      value={data.email}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Phone Number:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Phone Number"}
                      name={"phone"}
                      required={true}
                      value={data.phone}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      maxLength={10}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Linkedin Profile
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Linkedin Profile Url"}
                      name={"linkedIn"}
                      required={false}
                      value={data.linkedIn}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Current Organization:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Current Organization"}
                      name={"organization"}
                      required={true}
                      value={data.organization}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Position in the organization:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Position/ Designation"}
                      name={"position"}
                      required={true}
                      value={data.position}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>{" "}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    A brief Profile about yourself :
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      as={"textarea"}
                      rows={2}
                      placeholder={"A brief Profile about yourself"}
                      name={"bio"}
                      required={true}
                      value={data.bio}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Years of experience:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"experience"}
                      required={true}
                      value={data.experience}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select Answer
                      </option>
                      <option value={"0 - 4"}>0 - 4</option>
                      <option value={"5 - 9"}>5 - 9</option>
                      <option value={"10 - 14"}>10 - 14</option>
                      <option value={"15+"}>15+</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Domain of specialization: <br />
                    (Please check all that apply).
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Row>
                      {[
                        "Finance (Modelling, Valuation etc.)",
                        "Accounting",
                        "Marketing",
                        "Marketing Research",
                        "Sales and Distribution",
                        "Legal",
                        "Business Strategy",
                        "IP and Trademark",
                        "E-Commerce",
                        "Food and Beverages",
                        "Agriculture Product Development",
                        "Food Product Development",
                        "AI/ IoT/ ML in Agriculture",
                        "Precision Farming",
                        "Food Processing & Value Addition",
                        "Agricultural Biotechnology",
                        "Data Analytics",
                        "Waste to Wealth",
                        "Food Packaging and Storage",
                        "Coding",
                        "Other",
                      ].map((item, index) => (
                        <Col sm={12} md={6} key={item}>
                          <Form.Check
                            label={item}
                            value={item}
                            name="domain"
                            type={"checkbox"}
                            style={{ textAlign: "left" }}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              if (checked) {
                                setData({
                                  ...data,
                                  domain: [...data.domain, value],
                                });
                              } else {
                                setData({
                                  ...data,
                                  domain: data.domain.filter(
                                    (e) => e !== value
                                  ),
                                });
                              }
                            }}
                          />
                        </Col>
                      ))}
                      {data.domain.includes("Other") && (
                        <Form.Control
                          type={"text"}
                          placeholder={"Other (please specify)"}
                          name={"domain_other"}
                          required={false}
                          value={data.domain_other}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      )}
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Will you be able to spare 2-6 hours per week for online
                    mentoring session?
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"available"}
                      required={true}
                      value={data.available}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select Answer
                      </option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Upload your CV:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="file"
                      name={"cv"}
                      required={false}
                      // value={data.cv}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Is there anything else you would like to share with us?
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      as={"textarea"}
                      row={2}
                      placeholder={""}
                      name={"more"}
                      required={false}
                      value={data.more}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                md={{ span: 6, offset: 3 }}
                lg={{ span: 4, offset: 4 }}
              >
                <Button
                  type="submit"
                  className="px-4 mb-3 button--primary"
                  disabled={loading}
                  // style={{ borderRadius: "2em", backgroundColor: "#002147" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
}
