import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";

let myInitialValues = {
  name: "",
  gender: "",
  dob: "",
  address: "",
  address_post_office: "",
  address_pin_code: "",
  address_district: "",
  address_state: "",
  phone: "",
  email: "",
  email_alternate: "",
  qualification: "",
  qualification_other: "",
  experience: "",
  startup_name: "",
  startup_registered: "",
  startup_registered_application_date: "",
  startup_type: "",
  startup_type_other: "",
  startup_dpiit_recognized: "",
  startup_dpiit_recognized_application_date: "",
  startup_dpiit_number: "",
  startup_gst_number: "",
  startup_registration_willingness: "",
  startup_cin: "",
  startup_registered_address: "",
  startup_establishment_year: "",
  startup_website: "",
  startup_summary: "",
  startup_business_model: "",
  startup_motivation: "",
  startup_sector: "",
  startup_sector_other: "",
  startup_stage: "",
  startup_stage_other: "",
  startup_ready_time: "",
  technology_name: "",
  technology_innovation: "",
  technology_work: "",
  technology_solve: "",
  technology_usp: "",
  technology_target: "",
  technology_customer: "",
  technology_competitors: "",
  pitch_deck: null,
  more: "",
  how_found_us: "",
  how_found_us_other: "",
  declaration: false,
};

export default function ApplyIncubation() {
  const [data, setData] = useState(myInitialValues);
  const [validated, setValidated] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!data.declaration) {
      alert("Please check the box to confirm your Declaration");
      return;
    } else if (data.phone.length !== 10) {
      alert("Enter a valid Phone number(10 Digits");
      return;
    } else if (!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      alert("Enter a valid Email");
      return;
    } else if (
      !data.email_alternate ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email_alternate)
    ) {
      alert("Enter a valid alternate Email");
      return;
    } else if (data.email === data.email_alternate) {
      alert("Email Id and Alternative Email Id should be different.");
      return;
    } else if (
      data.startup_summary
        .trim()
        .split(" ")
        .filter((word) => word !== "").length >= 250
    ) {
      alert("Summary should be less than 200 words.");
      return;
    }
    if (form.checkValidity() === false) {
      // event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      alert("Fill all required field.");
      return;
    } else {
      setLoading(true);
      let fd = new FormData();
      fd.append("name", data.name);
      fd.append("gender", data.gender);
      fd.append("dob", data.dob);
      fd.append("address", data.address);
      fd.append("address_post_office", data.address_post_office);
      fd.append("address_pin_code", data.address_pin_code);
      fd.append("address_district", data.address_district);
      fd.append("address_state", data.address_state);
      fd.append("phone", data.phone);
      fd.append("email", data.email);
      fd.append("email_alternate", data.email_alternate);
      fd.append(
        "qualification",
        data.qualification === "Other"
          ? data.qualification_other
          : data.qualification
      );
      fd.append("experience", data.experience.toString());
      fd.append("startup_name", data.startup_name);
      fd.append("startup_registered", data.startup_registered);
      fd.append(
        "startup_registered_application_date",
        data.startup_registered_application_date
      );
      fd.append(
        "startup_type",
        data.startup_type === "Other"
          ? data.startup_type_other
          : data.startup_type
      );
      fd.append("startup_dpiit_recognized", data.startup_dpiit_recognized);
      fd.append(
        "startup_dpiit_recognized_application_date",
        data.startup_dpiit_recognized_application_date
      );
      fd.append("startup_dpiit_number", data.startup_dpiit_number);
      fd.append("startup_gst_number", data.startup_gst_number);
      fd.append(
        "startup_registration_willingness",
        data.startup_registration_willingness
      );
      fd.append("startup_cin", data.startup_cin);
      fd.append("startup_registered_address", data.startup_registered_address);
      fd.append("startup_establishment_year", data.startup_establishment_year);
      fd.append("startup_website", data.startup_website);
      fd.append("startup_summary", data.startup_summary);
      fd.append("startup_business_model", data.startup_business_model);
      fd.append("startup_motivation", data.startup_motivation);
      fd.append(
        "startup_sector",
        data.startup_sector === "Other"
          ? data.startup_sector_other
          : data.startup_sector
      );
      fd.append(
        "startup_stage",
        data.startup_stage === "Other"
          ? data.startup_stage_other
          : data.startup_stage
      );
      fd.append("startup_ready_time", data.startup_ready_time);
      fd.append("technology_name", data.technology_name);
      fd.append("technology_innovation", data.technology_innovation);
      fd.append("technology_work", data.technology_work);
      fd.append("technology_solve", data.technology_solve);
      fd.append("technology_usp", data.technology_usp);
      fd.append("technology_target", data.technology_target);
      fd.append("technology_customer", data.technology_customer);
      fd.append("technology_competitors", data.technology_competitors);
      fd.append("more", data.more);
      fd.append(
        "how_found_us",
        data.how_found_us === "Other"
          ? data.how_found_us_other
          : data.how_found_us
      );
      if (data.pitch_deck) {
        fd.append("pitch_deck", data.pitch_deck);
      }
      try {
        console.log(fd.get("name"));
        fetch(`${process.env.REACT_APP_BASE_URL}/apply/incubation`, {
          headers: {
            Accept: "application/json",
          },
          method: "POST",
          body: fd,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === "success") {
              setValidated(true);
              setFormSubmitted(true);
            }
            setLoading(false);
          });
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Container className="apply">
        <h2 className="heading--primary">
          Application for Incubation/Acceleration at <br /> Agri Business Incubation
          Foundation IIT Kharagpur
        </h2>
        {formSubmitted ? (
          <h4 className="heading-primary" style={{ padding: "2em 0" }}>
            Thank you for registering yourself for Incubation at ABIF!
            <br />
            We will get back to you shortly.
          </h4>
        ) : (
          <div className="apply--info">
            <p>
              Applications are invited from startups / ideas in agriculture and
              allied sector especially Precision Farming, Nanotechnology, AI /
              Machine Learning / IoT in Agriculture and other technology based
              agribusinesses / ideas, for getting incubation support under Agri
              Business Incubation Foundation IIT Kharagpur.
            </p>
            <p>
              For any queries, please feel free to write to{" "}
              <a href="mailto:info@abifiitkgp.com">info@abifiitkgp.com</a>
            </p>
          </div>
        )}
      </Container>
      <Container className="">
        {!formSubmitted && (
          <Form
            className="apply-form"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  A. Applicant's Details:
                </h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Full Name:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Full Name"}
                      name={"name"}
                      required={true}
                      value={data.name}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Gender:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"gender"}
                      required={true}
                      value={data.gender}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      // style={{ color: "#D3D3D3 !important" }}
                    >
                      <option value={""} disabled>
                        Select Gender
                      </option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      <option value={"others"}>Others</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Date of Birth:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"date"}
                      placeholder={"Date of Birth"}
                      name={"dob"}
                      required={true}
                      value={data.dob}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Address for correspondance:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      as={"textarea"}
                      rows={2}
                      placeholder={"Address for correspondance"}
                      name={"address"}
                      required={true}
                      value={data.address}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                    <Row>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          type="text"
                          placeholder={"Post Office"}
                          name={"address_post_office"}
                          required={true}
                          value={data.address_post_office}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          type="number"
                          placeholder={"Pin Code"}
                          name={"address_pin_code"}
                          required={true}
                          value={data.address_pin_code}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          type="text"
                          placeholder={"District"}
                          name={"address_district"}
                          required={true}
                          value={data.address_district}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          type="text"
                          placeholder={"State"}
                          name={"address_state"}
                          required={true}
                          value={data.address_state}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Phone Number:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Phone Number"}
                      name={"phone"}
                      required={true}
                      value={data.phone}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Email Id:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"email"}
                      placeholder={"your_email@email.com"}
                      name={"email"}
                      required={true}
                      value={data.email}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Alterantive Email Id:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"email"}
                      placeholder={"your_email_alternative@email.com"}
                      name={"email_alternate"}
                      required={true}
                      value={data.email_alternate}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Highest Qualification:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"qualification"}
                      required={true}
                      value={data.qualification}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select Highest Qualification
                      </option>
                      <option value={"Graduation"}>Graduation</option>
                      <option value={"Post Graduation"}>Post-Graduation</option>
                      <option value={"Phd"}>PhD</option>
                      <option value={"Other"}>Others</option>
                    </Form.Select>
                    {data.qualification === "Other" && (
                      <Form.Control
                        type="text"
                        name={"qualification_other"}
                        placeholder="If Others, please Specify"
                        required={false}
                        value={data.qualification_other}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                        className="mt-2"
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Professional experience (in years):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"number"}
                      placeholder={"Professional experience (in years)"}
                      name={"experience"}
                      required={true}
                      value={data.experience}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">B. Startup Details:</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Name of Startup:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Name of Startup"}
                      name={"startup_name"}
                      required={true}
                      value={data.startup_name}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Have you registered your company?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"startup_registered"}
                      required={true}
                      value={data.startup_registered}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select Answer
                      </option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                      <option value={"Application Under Process"}>
                        Application Under Process
                      </option>
                    </Form.Select>
                    {data.startup_registered ===
                      "Application Under Process" && (
                      <Form.Control
                        type="text"
                        name={"startup_registered_application_date"}
                        placeholder="Application Number?"
                        required={false}
                        value={data.startup_registered_application_date}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                        className="mt-2"
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    If yes, what is the legal status of the startup you have
                    formed?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"startup_type"}
                      required={false}
                      value={data.startup_type}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""}>Select Startup Type</option>
                      <option value={"Proprietorship"}>Proprietorship</option>
                      <option value={"Partnership"}>Partnership</option>
                      <option value={"Limited Liability Partnership"}>
                        Limited Liability Partnership
                      </option>
                      <option value={"Private Limited Company"}>
                        Private Limited Company
                      </option>
                      <option value={"Other"}>Other</option>
                    </Form.Select>
                    {data.startup_type === "Other" && (
                      <Form.Control
                        type="text"
                        placeholder="if other, please Specify."
                        name={"startup_type_other"}
                        required={false}
                        value={data.startup_type_other}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                        className="mt-2"
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Is it DPIIT recognized?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"startup_dpiit_recognized"}
                      required={false}
                      value={data.startup_dpiit_recognized}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""}>Select Answer</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                      <option value={"Application Under Process"}>
                        Application Under Process
                      </option>
                    </Form.Select>
                    {data.startup_dpiit_recognized ===
                      "Application Under Process" && (
                      <Form.Control
                        type="text"
                        name={"startup_dpiit_recognized_application_date"}
                        placeholder="Application Number?"
                        required={false}
                        value={data.startup_dpiit_recognized_application_date}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                        className="mt-2"
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    If yes, your number?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"If yes your number?"}
                      name={"startup_dpiit_number"}
                      required={false}
                      value={data.startup_dpiit_number}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    GST Number of your startup?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"GST Number of your startup?"}
                      name={"startup_gst_number"}
                      required={false}
                      value={data.startup_gst_number}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    If you do not have a registered company, are you willing to
                    register during the incubation period?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"startup_registration_willingness"}
                      required={false}
                      value={data.startup_registration_willingness}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""}>Select Answer</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Registration number / CIN (If registered):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Registration number / CIN (If registered)"}
                      name={"startup_cin"}
                      required={false}
                      value={data.startup_cin}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Registered address of the company:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      as={"textarea"}
                      rows={2}
                      placeholder={"Registered address of the company"}
                      name={"startup_registered_address"}
                      required={false}
                      value={data.startup_registered_address}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Establishment year:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"number"}
                      placeholder={"Establishment year"}
                      name={"startup_establishment_year"}
                      required={false}
                      value={data.startup_establishment_year}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Website:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Website"}
                      name={"startup_website"}
                      required={false}
                      value={data.startup_website}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  C. Brief about the Startup/Idea:
                </h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={12}
                    lg={12}
                    className="apply--form--label apply--form--label--required"
                  >
                    Executive summary / brief overview of your business proposal
                    (Limit to 200 words):
                  </Form.Label>
                  <Col sm={12} md={12} lg={12}>
                    <Form.Control
                      as={"textarea"}
                      rows={4}
                      placeholder={
                        "Executive summary / brief overview of your business proposal (In not more than 200 words)"
                      }
                      name={"startup_summary"}
                      required={true}
                      value={data.startup_summary}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={12}
                    lg={12}
                    className="apply--form--label apply--form--label--required"
                  >
                    Business model of the Startup / Idea:
                  </Form.Label>
                  <Col sm={12} md={12} lg={12}>
                    <Form.Control
                      as={"textarea"}
                      rows={4}
                      placeholder={"Business model of the Startup / Idea:"}
                      name={"startup_business_model"}
                      required={true}
                      value={data.startup_business_model}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={12}
                    lg={12}
                    className="apply--form--label apply--form--label--required"
                  >
                    Motivation behind Startup / Idea:
                  </Form.Label>
                  <Col sm={12} md={12} lg={12}>
                    <Form.Control
                      as={"textarea"}
                      rows={4}
                      placeholder={"Motivation behind Startup / Idea"}
                      name={"startup_motivation"}
                      required={true}
                      value={data.startup_motivation}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">D. Technology:</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Which focus area does your product/service belongs to:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"startup_sector"}
                      required={true}
                      value={data.startup_sector}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select answer
                      </option>
                      <option value={"Farm Mechanization"}>
                        Farm Mechanization
                      </option>
                      <option value={"AI/Machine Learning/IoT in Agriculture"}>
                        AI/Machine Learning/IoT in Agriculture
                      </option>
                      <option value={"Agricultural Bio-technology"}>
                        Agricultural Bio-technology
                      </option>
                      <option value={"Precision Farming"}>
                        Precision Farming
                      </option>
                      <option value={"Soil & Water Conservation Engineering"}>
                        Soil & Water Conservation Engineering
                      </option>
                      <option value={"Food Processing & Value Addition"}>
                        Food Processing & Value Addition
                      </option>
                      <option value={"Food Packaging & Storage"}>
                        Food Packaging & Storage
                      </option>
                      <option value={"Renewable Energy"}>
                        Renewable Energy
                      </option>
                      <option value={"Agri Supply Chain Management"}>
                        Agri Supply Chain Management
                      </option>
                      <option value={"Agri Waste to Wealth"}>
                        Agri Waste to Wealth
                      </option>
                      <option value={"Animal Husbandry"}>
                        Animal Husbandry
                      </option>
                      <option value={"Apiary"}>Apiary</option>
                      <option value={"Fisheries"}>Fisheries</option>
                      <option value={"Other"}>Others</option>
                    </Form.Select>
                    {data.startup_sector === "Other" && (
                      <Form.Control
                        className="mt-3"
                        type="text"
                        placeholder="please specify"
                        name={"startup_sector_other"}
                        required={false}
                        value={data.startup_sector_other}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Where are you in your product development / path to market
                    process?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"startup_stage"}
                      required={true}
                      value={data.startup_stage}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select Answer
                      </option>
                      <option value={"Concept- Idea stage"}>
                        Concept- Idea stage
                      </option>
                      <option
                        value={
                          "Proof of Concept: Some experiments done. Preliminary proof of concept exists"
                        }
                      >
                        Proof of Concept: Some experiments done. Preliminary
                        proof of concept exists
                      </option>
                      <option
                        value={
                          "Prototype: Several proofs of concept demonstrated"
                        }
                      >
                        Prototype: Several proofs of concept demonstrated
                      </option>
                      <option
                        value={
                          "Pilot: Minimum viable product (MVP): Pre-commercialization use. Prototype made and performance claims tested, trials have been done on sufficient scale to demonstrate interest of potential customer."
                        }
                      >
                        Pilot: Minimum viable product (MVP):
                        Pre-commercialization use. Prototype made and
                        performance claims tested, trials have been done on
                        sufficient scale to demonstrate interest of potential
                        customer.
                      </option>
                      <option
                        value={
                          "Production: Commercially ready. Real life testing conducted by potential customers. Product is ready for commercial sales."
                        }
                      >
                        Production: Commercially ready. Real life testing
                        conducted by potential customers. Product is ready for
                        commercial sales.
                      </option>
                      <option value={"Other"}>Other</option>
                    </Form.Select>
                    {data.startup_stage === "Other" && (
                      <Form.Control
                        className="mt-2"
                        type="text"
                        placeholder="please specify"
                        name={"startup_stage_other"}
                        required={false}
                        value={data.startup_stage_other}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    How long will it take for this Idea/innovation/technology is
                    completely developed and ready for the market (Expected in
                    months) ?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"startup_ready_time"}
                      required={true}
                      value={data.startup_ready_time}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select Answer
                      </option>
                      <option value={"Already in active use now"}>
                        Already in active use now
                      </option>
                      <option value={"1-6 months"}>1-6 months</option>
                      <option value={"7-12 months"}>7-12 months</option>
                      <option value={"12+ months"}>12+ months</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={12}
                    lg={12}
                    className="apply--form--label"
                  >
                    Please explain your technology in brief: Technology
                    Description: Intended product or service (Define scope
                    carefully). For a process, one needs to think of the product
                    that will result.:
                  </Form.Label>
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Name of the product / technology:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="Name of the product / technology"
                      name={"technology_name"}
                      required={true}
                      value={data.technology_name}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Novelty / Innovation in the Product / Service? (Device / new
                    material / algorithm / new process or any other):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="Novelty / Innovation in the Product / Service? (Device / new material / algorithm / new process or any other)"
                      name={"technology_innovation"}
                      required={true}
                      value={data.technology_innovation}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    What does it do? (Use simple and non-technical terms):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="What does it do? (Use simple and non-technical terms)"
                      name={"technology_work"}
                      required={true}
                      value={data.technology_work}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    What problem/pain does it solve?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="What problem/pain does it solve?"
                      name={"technology_solve"}
                      required={true}
                      value={data.technology_solve}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Explain USP (Unique Selling Proposition) of your product?
                    Please focus on “benefits” rather than “features” of the
                    technology to the end customer:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="Explain USP (Unique Selling Proposition) of your product? Please focus on “benefits” rather than “features” of the technology to the end customer"
                      name={"technology_usp"}
                      required={true}
                      value={data.technology_usp}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Your Target Market (Geography):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder=" Your Target Market (Geography)"
                      name={"technology_target"}
                      required={true}
                      value={data.technology_target}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Who is the primary customer of your product/service?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="Who is the primary customer of your product/service?"
                      name={"technology_customer"}
                      required={true}
                      value={data.technology_customer}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Does your technology have any intellectual property
                    protection? If not, what is your plan to protect your
                    technology from competitors?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="text"
                      placeholder="Does your technology have any intellectual property protection? If not, what is your plan to protect your technology from competitors?"
                      name={"technology_competitors"}
                      required={true}
                      value={data.technology_competitors}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  E. Additional information:
                </h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label"
                  >
                    Upload your pitch deck (if available):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      type="file"
                      name={"pitch_deck"}
                      required={false}
                      // value={data.pitch_deck}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Any other relevant information you would like us to know?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      as={"textarea"}
                      row={2}
                      placeholder="Any other relevant information you would like us to know?"
                      name={"more"}
                      required={false}
                      value={data.more}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    How did you find out about our incubation program?
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      name={"how_found_us"}
                      required={true}
                      value={data.how_found_us}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select Answer
                      </option>
                      <option value={"Facebook"}>Facebook</option>
                      <option value={"Instagram"}>Instagram</option>
                      <option value={"Linkedin/Twitter"}>
                        Linkedin/Twitter
                      </option>
                      <option value={"Website"}>Website</option>
                      <option value={"Word of mouth"}>Word of mouth</option>
                      <option value={"Through a mentor"}>
                        Through a mentor
                      </option>
                      <option value={"Other"}>Other</option>
                    </Form.Select>
                    {data.how_found_us === "Other" && (
                      <Form.Control
                        type="text"
                        placeholder="please specify"
                        name={"how_found_us_other"}
                        required={true}
                        value={data.how_found_us_other}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ textAlign: "left" }}>
              <Col sm={12} md={12} lg={12}>
                <p>
                  Note: Please do not include any confidential information while
                  describing any aspect of your technology, your company, or
                  your business plans in this application.
                </p>
              </Col>
              <Form.Group
                as={Col}
                className="apply--form--group"
                sm={12}
                md={12}
                lg={12}
              >
                <Form.Check
                  type="checkbox"
                  label="Declaration,"
                  name="declaration"
                  checked={data.declaration}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.checked });
                  }}
                />
                <Form.Label className="apply--form--label apply--form--label--required">
                  I hereby certify that the above statements are true and
                  correct to the best of my knowledge. I understand that any
                  false information may lead to the disqualification of my
                  application at any stage of the program.
                </Form.Label>
              </Form.Group>
            </Row>
            <Row>
              <Col
                sm={12}
                md={{ span: 6, offset: 3 }}
                lg={{ span: 4, offset: 4 }}
              >
                <Button
                  type="submit"
                  className="px-4 mb-3 button--primary"
                  disabled={loading}
                  // style={{ borderRadius: "2em", backgroundColor: "#002147" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
}
