import list from "../data/schemesList.json";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button, Card, FormControl } from "react-bootstrap";
import { useEffect, useState } from "react";
import { MdOpenInNew } from "react-icons/md";

export default function GovtInitiatives() {
  const [search, setSearch] = useState("");
  const [tempList, setTempList] = useState(list);
  const [ministry, setMinistry] = useState([]);
  const [keySectors, setKeySectors] = useState([]);
  const [uniqueMnames, setUniqueMnames] = useState([]);
  const [uniqueSectors, setUniqueSectors] = useState([]);

  const handleApply = () => {
    setTempList(
      list?.filter(
        (item) =>
          (search
            ? item.schname[0]?.toLowerCase().includes(search.toLowerCase()) ||
              item.title[0]?.toLowerCase().includes(search.toLowerCase()) ||
              item.mname?.some((mname) =>
                mname.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.sector?.some((sector) =>
                sector.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.brief?.some((brief) =>
                brief.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.EligibilityCriteria?.some((criteria) =>
                criteria.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.quantumSize?.some((size) =>
                size.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.benefitTags?.some((tag) =>
                tag.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.tenure?.some((tenure) =>
                tenure.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.benefits?.some((benefit) =>
                benefit.toLowerCase().includes(search.toLowerCase())
              ) ||
              item.notes?.some((note) =>
                note.toLowerCase().includes(search.toLowerCase())
              )
            : true) &&
          (ministry?.length > 0
            ? ministry.some(
                (i) => item.title?.includes(i) || item.mname?.includes(i)
              )
            : true) &&
          (keySectors.length > 0
            ? keySectors.some((i) => item.sector?.includes(i))
            : true)
      )
    );
  };

  const handleReset = () => {
    setSearch("");
    setMinistry([]);
    setTempList(list);
  };

  useEffect(() => {
    const allMnames = [
      ...list.flatMap((item) => item.mname),
      ...list.flatMap((item) => item.title),
    ];
    const allSectors = list.flatMap((item) => item.sector);
    const uniqueMnamesSet = new Set(allMnames);
    const uniqueSectorSet = new Set(allSectors);
    setUniqueSectors(
      Array.from(uniqueSectorSet)
        .filter((i) => i)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    );
    setUniqueMnames(
      Array.from(uniqueMnamesSet)
        .filter((i) => i)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    );
    setTempList(list);
  }, []);

  useEffect(() => {
    handleApply();
  }, [search, ministry, keySectors]);

  return (
    <>
      <Container className="notice">
        <Row>
          <Col>
            <h2 className="heading--primary">
              Govt. Initiatives/Schemes for Startups
            </h2>
            <p className="heading--secondary text-center">
              Initiatives by Central Ministries and Departments
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="heading--secondary">Overview</h2>
            <ul className="text-justify">
              <li>
                Welcome to the Govt. Initiatives section of the Agri Business
                Incubation Foundation IIT Kharagpur website. This section
                provides a curated list of schemes initiated by the Government
                of India to support and promote agri-business startups and
                innovations. The information has been collated from public
                sources.
              </li>
              <li>
                The government recognizes the crucial role of agri-business
                startups in driving innovation, sustainability, and economic
                growth in the agricultural sector. Various ministries and
                departments have introduced schemes to provide financial,
                infrastructural, and regulatory support to agri-entrepreneurs.
                The listed schemes cover areas like agri-tech, food processing,
                sustainable farming, rural development, and more.
              </li>
              <li>
                Each scheme's details, including objectives, eligibility
                criteria, application process, and benefits, are provided.
                Please note that the information may change, so it's advisable
                to refer to the official websites for the latest updates.
              </li>
              <li>
                The Govt. Initaitives section serves as a valuable resource for
                agri-entrepreneurs to explore government support measures.
                Leverage these schemes to propel your agri-business startup
                towards success and contribute to the transformation of India's
                agricultural landscape.
              </li>
              <li>
                <b>Disclaimer: &nbsp;</b>
                The information provided is subject to change. Refer to official
                sources for the most up-to-date information on individual
                schemes. This schemes information is adapted from the{" "}
                <a
                  href="https://www.startupindia.gov.in/content/sih/en/government-schemes.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Startup India website
                </a>{" "}
                and modified for the specific context of Agri-Business
                Incubation Foundation IIT Kharagpur.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12} className="text-start mb-4">
            <Row className="gap-3">
              <p className="fw-bold m-0 fs-4 text-start">Filters/Search</p>
              <FormControl
                type="text"
                placeholder="Search..."
                className=""
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div>
                <p className="m-0 fs-5 text-start">Key Sectors:</p>
                {uniqueSectors?.map((item) => (
                  <Form.Check
                    type="checkbox"
                    label={item}
                    value={item}
                    checked={keySectors.includes(item)}
                    onChange={(e) => {
                      const { value, checked } = e.target;
                      setKeySectors((prevSelected) => {
                        if (checked) {
                          return [...prevSelected, value];
                        } else {
                          return prevSelected.filter((name) => name !== value);
                        }
                      });
                    }}
                  />
                ))}
              </div>
              <div>
                <p className="m-0 fs-5 text-start">Ministry/Departments:</p>
                {uniqueMnames?.map((item) => (
                  <Form.Check
                    type="checkbox"
                    label={item}
                    value={item}
                    checked={ministry.includes(item)}
                    onChange={(e) => {
                      const { value, checked } = e.target;
                      setMinistry((prevSelected) => {
                        if (checked) {
                          return [...prevSelected, value];
                        } else {
                          return prevSelected.filter((name) => name !== value);
                        }
                      });
                    }}
                  />
                ))}
              </div>
              <Col>
                <Row>
                  <Button onClick={handleReset}>Reset</Button>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Button onClick={handleApply}>Apply</Button>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={8} sm={12}>
            <Row className="g-3">
              {tempList?.map((item, index) => (
                <Col sm={12} md={12} key={index}>
                  <Card>
                    <Card.Body className="text-justify">
                      <Card.Title className="fs-3 fw-bold text-center">
                        {item?.schname?.[0]}
                      </Card.Title>
                      <Card.Text>
                        <p>
                          <span className="fw-bold">Ministry:</span>{" "}
                          {item?.title?.[0]}
                        </p>
                        {item?.mname?.length > 0 && (
                          <p>
                            <span className="fw-bold">Department:</span>{" "}
                            {item?.mname?.[0]}
                          </p>
                        )}
                        {item?.sector?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">Key Sector Covered:</p>
                            <ul>
                              {item?.sector?.map((el) => (
                                <li>{el}</li>
                              ))}
                            </ul>{" "}
                          </div>
                        )}
                        {item?.brief?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">Brief:</p>
                            <ul>
                              {item?.brief?.map((criteria) => (
                                <li>{criteria}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item.EligibilityCriteria?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">
                              Eligibility Criteria:{" "}
                            </p>
                            <ul>
                              {item?.EligibilityCriteria?.map((criteria) => (
                                <li>{criteria}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item.quantumSize?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">
                              Quantum/ size of overall fund (only for funding):
                            </p>
                            <ul>
                              {item?.quantumSize?.map((criteria) => (
                                <li>{criteria}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item?.benefitTags?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">Benefit Tags: </p>
                            <ul>
                              {item?.benefitTags?.map((criteria) => (
                                <li>{criteria}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item?.tenure?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">Tenure: </p>
                            <ul>
                              {item?.tenure?.map((criteria) => (
                                <li>{criteria}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item?.benefits?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">Benefits: </p>
                            <ul>
                              {item?.benefits?.map((criteria) => (
                                <li>{criteria}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item?.notes?.length > 0 && (
                          <div>
                            <p className="fw-bold m-0">Notes: </p>
                            <ul>
                              {item?.notes?.map((criteria) => (
                                <li>{criteria}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item?.linktoApplication?.length > 0 && (
                          <div className="text-end">
                            <Button
                              className="d-inline-flex align-items-center ml-auto"
                              href={item?.linktoApplication?.[0]}
                              target="_blank"
                              rel="noopener noreferrer"
                              variant="primary"
                            >
                              Application Link <MdOpenInNew size={16} className="ms-2 align-middle"   />
                            </Button>
                          </div>
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
