import { useEffect, useState } from "react";
import { getPastEventList } from "../api/api";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import UpcomingEvents from "../components/Home/UpcomingEvents";

export default function Event() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getPastEventList().then((result) => {
      setList(result);
    });
  }, []);
  return (
    <>
      <Container className="notice">
        <UpcomingEvents display={"complete"} />
        <Row>
          <Col>
            <h2 className="heading--primary wow animate__animated animate__fadeInUp">
              Past Events
            </h2>
          </Col>
        </Row>
        {list && (
          <Row style={{ alignItems: "stretch", alignContent: "stretch" }}>
            {
              list?.length > 0 &&
                list.reverse().map((item, index) => (
                  <Col
                    md={12}
                    lg={12}
                    style={{
                      alignItems: "stretch",
                      marginBottom: "1em",
                    }}
                    className="wow animate__animated animate__fadeInUp"
                  >
                    <Card
                      style={{
                        alignSelf: "stretch",
                        height: "100%",
                        textAlign: "justify",
                      }}
                    >
                      <Card.Body>
                        <img
                          src={item.image}
                          style={{
                            width: "30%",
                            float: "left",
                            marginRight: "1em",
                          }}
                          alt="event"
                        />
                        <Card.Title className="home__upcoming--title">
                          {item.title}
                        </Card.Title>
                        <Card.Text
                          style={{
                            marginBottom: "auto",
                            textAlign: "justify",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {/* [{item.date}] */}
                          {item.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              /*  <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>

                  <th>Title</th>
                  <th>Venue</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Last Date</th>
                  <th>Document(s)</th>
                  <th>Registration Link</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.venue}</td>
                    <td>{new Date(item.start_date).toLocaleString()}</td>
                    <td>{new Date(item.end_date).toLocaleString()}</td>
                    <td>{new Date(item.last_date).toLocaleString()}</td>
                    <td>
                      {item.flyer && (
                        <a href={item.flyer} target="_blank">
                          Open Flyer
                        </a>
                      )}
                      {item.schedule && (
                        <a href={item.schedule} target="_blank">
                          Open Schedule
                        </a>
                      )}
                    </td>
                    <td>
                      <a
                        href={`/events/register?event_id=${item.id}`}
                        target="_blank"
                      >
                        Click Here
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>*/
            }
          </Row>
        )}
      </Container>
    </>
  );
}
