import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { getMentorList, submitAppointment } from "../api/api";

const initialValues = {
  mentor: "",
  name: "",
  email: "",
  phone: "",
  message: "",
};
export default function Mentors() {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState(initialValues);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleClose = () => {
    setData(initialValues);
    setFormSubmitted(false);
    setShow(false);
  };
  const handleShow = () => {
    setData(initialValues);
    setFormSubmitted(false);
    setShow(true);
  };

  const handleSubmit = () => {
    if (!data.mentor) {
      alert("Unknown Error!");
    } else if (!data.name) {
      alert("Name is required");
    } else if (
      !data.email ||
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(data.email)
    ) {
      alert("Enter a valid Email");
    } else if (!data.phone || data.phone.length !== 10) {
      alert("Enter a valid phone number");
    } else if (!data.message) {
      alert("Query field cannot be empty");
    } else if (
      data.message
        .trim()
        .split(/\s+/)
        .filter((word) => word !== "").length > 100
    ) {
      alert("Query can't me more than 100 words");
    } else {
      submitAppointment(data)
        .then((result) => {
          setData(initialValues);
          setFormSubmitted(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getMentorList().then((result) => {
      setList(result);
    });
  }, []);
  return (
    <>
      <Container className="mentor">
        <Row>
          <h2 className="heading--primary">Mentors</h2>
        </Row>
        <Row>
          <Col sm={12}>
            <h2
              className="heading--secondary"
              style={{ textAlign: "center", marginBottom: "1em" }}
            >
              Technology Experts
            </h2>
          </Col>
          {list
            // ?.slice(
            //   (currentPage - 1) * itemsPerPage,
            //   currentPage * itemsPerPage
            // )
            ?.filter((i) => i.category === "Technology Experts")
            ?.map((item, index) => (
              <Col
                sm={12}
                md={12}
                style={{
                  alignItems: "stretch",
                  marginBottom: "1em",
                }}
                className=""
              >
                <Card className="wow animate__animated animate__fadeInUp mentor--card">
                  {item.image && (
                    <Card.Img
                      variant="top"
                      src={item.image}
                      className="mentor--image"
                    />
                  )}
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {item.position}
                    </Card.Subtitle>
                    <Card.Text style={{ margin: 0 }}>
                      <strong>Area of Expertise:</strong>
                      <br />
                      {item.info}
                    </Card.Text>
                    <div>
                      <a
                        href={`/mentors/${item.slug}`}
                        style={{ marginRight: "1em" }}
                      >
                        <Button
                          variant="primary"
                          className="mt-2 button--primary"
                        >
                          View More
                        </Button>
                      </a>
                      <Button
                        variant="primary"
                        className="mt-2 button--primary"
                        onClick={() => {
                          handleShow();
                          setData({ ...data, mentor: item.name });
                        }}
                      >
                        Book an Appointment
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          <Col sm={12}>
            <h2
              className="heading--secondary"
              style={{ textAlign: "center", marginBottom: "1em" }}
            >
              Industry Experts
            </h2>
          </Col>
          {list
            // ?.slice(
            //   (currentPage - 1) * itemsPerPage,
            //   currentPage * itemsPerPage
            // )
            ?.filter((i) => i.category === "Industry Experts")
            ?.map((item, index) => (
              <Col
                sm={12}
                md={12}
                style={{
                  alignItems: "stretch",
                  marginBottom: "1em",
                }}
                className=""
              >
                <Card className="wow animate__animated animate__fadeInUp mentor--card">
                  {item.image && (
                    <Card.Img
                      variant="top"
                      src={item.image}
                      className="mentor--image"
                    />
                  )}
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {item.position}
                    </Card.Subtitle>
                    <Card.Text style={{ margin: 0 }}>
                      <strong>Area of Expertise:</strong>
                      <br />
                      {item.info}
                    </Card.Text>
                    <div>
                      <a
                        href={`/mentors/${item.slug}`}
                        style={{ marginRight: "1em" }}
                      >
                        <Button
                          variant="primary"
                          className="mt-2 button--primary"
                        >
                          View More
                        </Button>
                      </a>
                      <Button
                        variant="primary"
                        className="mt-2 button--primary"
                        onClick={() => {
                          handleShow();
                          setData({ ...data, mentor: item.name });
                        }}
                      >
                        Book an Appointment
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        {/* <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "max-content",
            margin: "0 auto",
          }}
        >
          <Pagination style={{ alignItems: "center", textAlign: "center" }}>
            {Array.from(
              { length: Math.ceil(list.length / itemsPerPage) },
              (_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePagination(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </Row> */}
      </Container>
      <Modal show={show} onHide={handleClose} style={{ textAlign: "left" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {formSubmitted ? (
              <>We have received your appointment</>
            ) : (
              <>Book an Appointment with {data.mentor}</>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSubmitted ? (
            <p className="" style={{ textAlign: "center" }}>
              Thank you for submitting yur query for appointment.
              <br />
              We will get back to you shortly.
            </p>
          ) : (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="full name"
                  value={data.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number (10 Digits only)"
                  value={data.phone}
                  onChange={(e) => {
                    setData({ ...data, phone: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Your Query (Limit to 60 words)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={data.message}
                  onChange={(e) => {
                    setData({ ...data, message: e.target.value });
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!formSubmitted && (
            <Button variant="primary" onClick={handleSubmit}>
              Book Appointment
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
