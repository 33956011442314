import { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { submitQuery } from "../api/api";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("pending");
  const handleSubmit = () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Enter a Valid Email!");
    } else if (!name || !message) {
      alert("Enter name or message");
    } else {
      setStatus("loading");
      submitQuery({ name, email, message })
        .then((result) => {
          setEmail("");
          setMessage("");
          setName("");
          setStatus("success");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="contact">
        <h2 className="heading--primary wow animate__animated animate__fadeInUpy">
          Contact Us
        </h2>
        <Container>
          <Row>
            <Col style={{ padding: "1em 0" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14763.828606897065!2d87.3137659!3d22.3174602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d440300000001%3A0xdafddd18adf1684e!2sAGRI%20BUSINESS%20INCUBATION%20FOUNDATION%20IIT%20Kharagpur!5e0!3m2!1sen!2sin!4v1691071738718!5m2!1sen!2sin"
                width="600"
                height="500"
                // style="border:0;"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Google Maps"
              ></iframe>
            </Col>
            <Col md="auto" style={{ flexGrow: 1 }}>
              <h3 className="heading--primary wow animate__animated animate__fadeInUp">
                Write to Us
              </h3>
              {status === "pending" ? (
                <Form>
                  <Form.Group className="mb-3 wow animate__animated animate__fadeInUp">
                    {/* <Form.Label>Name</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 wow animate__animated animate__fadeInUp">
                    {/* <Form.Label>Email address</Form.Label> */}
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="name@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 wow animate__animated animate__fadeInUp">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder={"Your Message"}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    // type="submit"
                    className="w-100 button--primary wow animate__animated animate__fadeInUp"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Form>
              ) : status === "loading" ? (
                <Col sm={12} md={12}>
                  <p className="wow animate__animated animate__fadeInUp">
                    Storing your query/message...
                  </p>
                </Col>
              ) : status === "success" ? (
                <Col sm={12} md={12}>
                  <p className="wow animate__animated animate__fadeInUp">
                    Query/Message stored Successfully...
                  </p>
                </Col>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
