import React from "react";

const Loader = () => {
  return (
    <div className="loader--container">
      <img className="loader--logo" src="/assets/images/logo.png" alt="Logo" />
    </div>
  );
};

export default Loader;
