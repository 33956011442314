import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function Apply() {
  return (
    <>
      <Container className="apply">
        <h2 className="heading--primary">
          Application for Incubation at <br /> Agri Business Incubation
          Foundation IIT Kharagpur
        </h2>
        <div className="apply--info">
          <p>
            Applications are invited from startups / ideas in agriculture and
            allied sector especially Precision Farming, Nanotechnology, AI /
            Machine Learning / IoT in Agriculture and other technology based
            agribusinesses / ideas, for getting incubation support under Agri
            Business Incubation Foundation IIT Kharagpur.
          </p>
          <p>
            For any queries, please feel free to write to{" "}
            <a href="mailto:info@abifiitkgp.com">info@abifiitkgp.com</a>
          </p>
        </div>
      </Container>
      <Container className="">
        <Form className="apply-form">
          <Row className="mt-3">
            <Col sm={12}>
              <h3 className="apply--section--heading">
                A. Applicant's Details:
              </h3>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Full Name:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"Full Name"}
                    name={"name"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Gender:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"gender"} required={true}>
                    <option>Select Gender</option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                    <option value={"others"}>Others</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Date of Birth:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"date"}
                    placeholder={"Date of Birth"}
                    name={"dob"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Address for correspondance:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    as={"textarea"}
                    rows={2}
                    placeholder={"Address for correspondance"}
                    name={"address"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Phone Number:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"Phone NUmber"}
                    name={"phone"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Email Id:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"email"}
                    placeholder={"your_email@email.com"}
                    name={"email"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Native State:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"State"}
                    name={"state"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Highest Qualification:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"qualification"} required={true}>
                    <option>Select Highest Qualification</option>
                    <option value={"graduation"}>Graduation</option>
                    <option value={"post_grafuation"}>Post-Graduation</option>
                    <option value={"phd"}>PhD</option>
                    <option value={"others"}>Others</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Professional experience (in years):
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"number"}
                    placeholder={"Professional experience (in years)"}
                    name={"experience"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={12}>
              <h3 className="apply--section--heading">B. Startup Details:</h3>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Name of Startup:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"Name of Startup"}
                    name={"startup_name"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Have you registered your company?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"company_registered"} required={true}>
                    <option>Select Answer</option>
                    <option value={"yes"}>Yes</option>
                    <option value={"no"}>No</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  If yes, what is the legal status of the entity you have
                  formed?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"startup_type"} required={false}>
                    <option>Select Firm Type</option>
                    <option value={"Proprietorship"}>Proprietorship</option>
                    <option value={"Partnership"}>Partnership</option>
                    <option value={"Limited Liability Partnership"}>
                      Limited Liability Partnership
                    </option>

                    <option value={"Private Limited Company"}>
                      Private Limited Company
                    </option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Is it DPIIT recognized?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"dpiit_recognized"} required={false}>
                    <option>Is it DPIIT recognized?</option>
                    <option value={"yes"}>Yes</option>
                    <option value={"no"}>No</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  If yes, your number?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"If yes your number?"}
                    name={"dpiit_number"}
                    required={false}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  GST Number of your firm?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"GST Number of your firm?"}
                    name={"gst_number"}
                    required={false}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  If you do not have a registered company, are you willing to
                  register during the incubation period?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select
                    name={"registration_willingness"}
                    required={false}
                  >
                    <option>
                      If you do not have a registered company, are you willing
                      to register during the incubation period?
                    </option>
                    <option value={"yes"}>Yes</option>
                    <option value={"no"}>No</option>
                    <option value={"maybe"}>Maybe</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Registration number / CIN (If registered):
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"Registration number / CIN (If registered)"}
                    name={"cin"}
                    required={false}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Registered address of the company:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    as={"textarea"}
                    rows={2}
                    placeholder={"Registered address of the company"}
                    name={"registered_address"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Establishment year:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"number"}
                    placeholder={"Establishment year"}
                    name={"year"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Website:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Control
                    type={"text"}
                    placeholder={"Website"}
                    name={"website"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={12}>
              <h3 className="apply--section--heading">
                C. Brief about the Startup/Idea:
              </h3>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={12}
                  lg={12}
                  className="apply--form--label"
                >
                  Executive summary / brief overview of your business proposal
                  (In not more than 200 words):
                </Form.Label>
                <Col sm={12} md={12} lg={12}>
                  <Form.Control
                    as={"textarea"}
                    rows={4}
                    placeholder={
                      "Executive summary / brief overview of your business proposal (In not more than 200 words)"
                    }
                    name={"summary"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={12}
                  lg={12}
                  className="apply--form--label"
                >
                  Business model of the Startup / Idea::
                </Form.Label>
                <Col sm={12} md={12} lg={12}>
                  <Form.Control
                    as={"textarea"}
                    rows={4}
                    placeholder={"Business model of the Startup / Idea:"}
                    name={"business_model"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={12}
                  lg={12}
                  className="apply--form--label"
                >
                  Motivation behind Startup / Idea:
                </Form.Label>
                <Col sm={12} md={12} lg={12}>
                  <Form.Control
                    as={"textarea"}
                    rows={4}
                    placeholder={"Motivation behind Startup / Idea"}
                    name={"motivation"}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={12}>
              <h3 className="apply--section--heading">D. Technology:</h3>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Which focus area does your product/service belongs to:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"startup_sector"} required={true}>
                    <option>
                      Which focus area does your product/service belongs to
                    </option>
                    <option value={"Farm Mechanization"}>
                      Farm Mechanization
                    </option>

                    <option value={"AI/Machine Learning/IoT in Agriculture"}>
                      AI/Machine Learning/IoT in Agriculture
                    </option>

                    <option value={"Agricultural Bio-technology"}>
                      Agricultural Bio-technology
                    </option>

                    <option value={"Precision Farming"}>
                      Precision Farming
                    </option>

                    <option value={"Soil & Water Conservation Engineering"}>
                      Soil & Water Conservation Engineering
                    </option>

                    <option value={"Food Processing & Value Addition"}>
                      Food Processing & Value Addition
                    </option>

                    <option value={"Food Packaging & Storage"}>
                      Food Packaging & Storage
                    </option>

                    <option value={"Renewable Energy"}>Renewable Energy</option>

                    <option value={"Agri Supply Chain Management"}>
                      Agri Supply Chain Management
                    </option>

                    <option value={"Agri Waste to Wealth"}>
                      Agri Waste to Wealth
                    </option>

                    <option value={"Animal Husbandry"}>Animal Husbandry</option>
                    <option value={"Apiary"}>Apiary</option>
                    <option value={"Fisheries"}>Fisheries</option>
                    <option value={"Others"}>Others</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  Where are you in your product development / path to market
                  process?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"startup_stage"} required={true}>
                    <option>
                      Where are you in your product development / path to market
                      process?
                    </option>
                    <option value={"Concept- Idea stage"}>
                      Concept- Idea stage
                    </option>

                    <option
                      value={
                        "Proof of Concept: Some experiments done. Preliminary proof of concept exists"
                      }
                    >
                      Proof of Concept: Some experiments done. Preliminary proof
                      of concept exists
                    </option>

                    <option
                      value={
                        "Prototype: Several proofs of concept demonstrated"
                      }
                    >
                      Prototype: Several proofs of concept demonstrated
                    </option>

                    <option
                      value={
                        "Pilot: Minimum viable product (MVP): Pre-commercialization use. Prototype made and performance claims tested, trials have been done on sufficient scale to demonstrate interest of potential customer."
                      }
                    >
                      Pilot: Minimum viable product (MVP): Pre-commercialization
                      use. Prototype made and performance claims tested, trials
                      have been done on sufficient scale to demonstrate interest
                      of potential customer.
                    </option>

                    <option
                      value={
                        "Production: Commercially ready. Real life testing conducted by potential customers. Product is ready for commercial sales."
                      }
                    >
                      Production: Commercially ready. Real life testing
                      conducted by potential customers. Product is ready for
                      commercial sales.
                    </option>
                    <option value={"Other"}>Other</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={3}
                  lg={3}
                  className="apply--form--label"
                >
                  How long will it take for this innovation/technology is
                  completely developed and ready for the market (Expected in
                  months) ?:
                </Form.Label>
                <Col sm={12} md={9} lg={9}>
                  <Form.Select name={"startup_ready_time"} required={true}>
                    <option>
                      How long will it take for this innovation/technology is
                      completely developed and ready for the market (Expected in
                      months) ?
                    </option>
                    <option value={"Already in active use now"}>
                      Already in active use now
                    </option>
                    <option value={"1-6 months"}>1-6 months</option>
                    <option value={"7-12 months"}>7-12 months</option>
                    <option value={"12+ months"}>12+ months</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>

            <Col
              sm={12}
              md={12}
              lg={12}
              // className="mb-3"
            >
              <Form.Group as={Row} className="apply--form--group">
                <Form.Label
                  column
                  sm={12}
                  md={12}
                  lg={12}
                  className="apply--form--label"
                >
                  Please explain your technology in brief: Technology
                  Description: Intended product or service (Define scope
                  carefully). For a process, one needs to think of the product
                  that will result.:
                </Form.Label>
                undefined
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}
